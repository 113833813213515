import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
  transform(value: number, precision: string = '.0'): string {
    const regexp = /^\.\d+$/;

    if (this.isNullOrUndefined(value)) {
      return '';
    }

    if (!regexp.test(precision)) {
      console.error('Invalid precision in file size pipe.');
      return value?.toString();
    }

    const byteUnits = ['bytes', 'KB', 'MB', 'GB', 'TB'];
    const precisionNumber = Number(precision.slice(1));
    let i = 0;

    while (value > 1024) {
      value = value / 1024;
      if (i < byteUnits.length) {
        i++;
      }
    }

    if (i === 0) {
      return `${value} ${byteUnits[i]}`;
    }

    return `${value.toFixed(precisionNumber)} ${byteUnits[i]}`;
  }

  private isNullOrUndefined(value: number): boolean {
    return value === null || value === undefined;
  }
}
