interface LabelPath {
  label: string;
  path: string;
}

export function generateLabelPath(path: string): LabelPath[] {
  if (!path) {
    return [];
  }

  const pathMetadata = path.split(';');
  const paths = pathMetadata[0].split('/').filter((p) => !!p);

  return paths.map<LabelPath>((current, index) => ({
    label: current,
    path: `/${paths.slice(0, ++index).join('/')}`,
  }));
}
