import {
  CourseContentComponent,
  Image,
  RichText,
} from './course-content-component';

export interface CourseContentLayout {
  type: 'LAYOUT';
  content:
    | SingleColumnLayout
    | ThreeColumnLayout
    | ThreeColumnImageContentLayout;
}

export enum LayoutType {
  SINGLE_COLUMN = 'SINGLE_COLUMN',
  THREE_COLUMN = 'THREE_COLUMN',
  THREE_COLUMN_IMAGE_CONTENT = 'THREE_COLUMN_IMAGE_CONTENT',
}

export interface SingleColumnLayout {
  type: LayoutType.SINGLE_COLUMN;
  components: [CourseContentComponent];
}

export interface ThreeColumnLayout {
  type: LayoutType.THREE_COLUMN;
  components: [
    CourseContentComponent,
    CourseContentComponent,
    CourseContentComponent
  ];
}

export interface ThreeColumnImageContentLayout {
  type: LayoutType.THREE_COLUMN_IMAGE_CONTENT;
  components: [
    { image: Image; content: RichText },
    { image: Image; content: RichText },
    { image: Image; content: RichText }
  ];
}
