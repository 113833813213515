import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AccessRightDirectiveModule } from '@directives/access-right/access-right.module';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule as PrimeTableModule } from 'primeng/table';
import { ButtonColumnComponent } from './components/button-column/button-column.component';
import { DateColumnComponent } from './components/date-column/date-column.component';
import { StatusColumnComponent } from './components/status-column/status-column.component';
import { TagColumnComponent } from './components/tag-column/tag-column.component';
import { TableComponent } from './table.component';

@NgModule({
  declarations: [
    ButtonColumnComponent,
    DateColumnComponent,
    TagColumnComponent,
    TableComponent,
    StatusColumnComponent,
  ],
  imports: [
    AccessRightDirectiveModule,
    CommonModule,
    FlexLayoutModule,
    PaginatorModule,
    PrimeTableModule,
  ],
  exports: [TableComponent],
})
export class TableModule {}
