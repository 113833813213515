import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiResponse, PaginationResult } from '@models/api';
import { GetAllCalendarBlockRequest } from '@models/api/calendar-block/get-all-calendar-block-request';
import { CalendarBlock, CalendarBlockListing } from '@models/calendar-block';
import { Pagination, SortEvent } from '@models/table';
import { EnvironmentConfig, ENV_CONFIG } from '@utils/http/environment-config';
import { HttpParamsGenerator } from '@utils/http/http-params-generator';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CalendarBlockApiService {
  private apiUrl = `${this.envConfig.environment.baseUrl}/api/calendar-block`;

  constructor(
    @Inject(ENV_CONFIG) private envConfig: EnvironmentConfig,
    private http: HttpClient
  ) {}

  getAll(
    pagination: Pagination,
    sort: SortEvent<CalendarBlockListing>,
    requestParams: Partial<GetAllCalendarBlockRequest>
  ): Observable<PaginationResult<CalendarBlockListing[]>> {
    const value = { ...pagination, sorting: [sort], ...requestParams };
    const params = new HttpParamsGenerator(value).generate();
    return this.http.get<PaginationResult<CalendarBlockListing[]>>(
      this.apiUrl,
      {
        params,
      }
    );
  }

  getOne(id: number): Observable<ApiResponse<CalendarBlock>> {
    return this.http.get<ApiResponse<CalendarBlock>>(`${this.apiUrl}/${id}`);
  }

  create(calendarBlock: CalendarBlock): Observable<ApiResponse<CalendarBlock>> {
    return this.http.post<ApiResponse<CalendarBlock>>(
      this.apiUrl,
      calendarBlock
    );
  }

  update(calendarBlock: CalendarBlock): Observable<ApiResponse<CalendarBlock>> {
    return this.http.put<ApiResponse<CalendarBlock>>(
      `${this.apiUrl}/${calendarBlock.id}`,
      calendarBlock
    );
  }
}
