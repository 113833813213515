import { Observable, Subscriber } from 'rxjs';

export interface SubscribeCloseEvent<T> {
  closeEvent: () => Observable<T>;
}

export abstract class AbstractDialogService<T = any> {
  private _subscriber: Subscriber<T>;

  visible = false;

  constructor() {}

  protected openDialog(): SubscribeCloseEvent<T> {
    this.visible = true;
    return { closeEvent: () => this.createCloseObservable() };
  }

  protected closeDialog(data?: T): void {
    this.visible = false;
    if (this._subscriber) {
      this._subscriber.next(data);
      this._subscriber.complete();
    }
  }

  private createCloseObservable(): Observable<T> {
    return new Observable((subscriber) => (this._subscriber = subscriber));
  }
}
