import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiResponse, Pagination, PaginationResult } from '@models/api';
import { ChangePassword } from '@models/api/account';
import { GetVisitorSearchCriteria } from '@models/api/visitor';
import { SortEvent } from '@models/table';
import { Visitor, VisitorAccessTime, VisitorListing } from '@models/visitor';
import { EnvironmentConfig, ENV_CONFIG } from '@utils/http/environment-config';
import { HttpParamsGenerator } from '@utils/http/http-params-generator';
import { Observable } from 'rxjs';

interface GetAllVisitorQuery {
  keyword: string;
  isArchived: boolean;
  isPendingArchive: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class VisitorApiService {
  private apiUrl = `${this.envConfig.environment.baseUrl}/api/visitor`;

  constructor(
    @Inject(ENV_CONFIG) private envConfig: EnvironmentConfig,
    private http: HttpClient
  ) {}

  getAll(
    pagination: Pagination,
    sort: SortEvent<VisitorListing>,
    query: Partial<GetVisitorSearchCriteria>
  ): Observable<PaginationResult<VisitorListing[]>> {
    const value = {
      ...pagination,
      ...query,
      sorting: [sort],
    };
    const params = new HttpParamsGenerator(value).generate();
    return this.http.get<PaginationResult<VisitorListing[]>>(`${this.apiUrl}`, {
      params,
    });
  }

  getOne(id: number): Observable<ApiResponse<Visitor>> {
    return this.http.get<ApiResponse<Visitor>>(`${this.apiUrl}/${id}`);
  }

  create(visitor: Visitor): Observable<ApiResponse<Visitor>> {
    return this.http.post<ApiResponse<Visitor>>(`${this.apiUrl}`, visitor);
  }

  update(visitor: Visitor): Observable<ApiResponse<Visitor>> {
    return this.http.put<ApiResponse<Visitor>>(`${this.apiUrl}`, visitor);
  }

  archive(ids: number[]): Observable<null> {
    return this.http.put<null>(`${this.apiUrl}/archive`, { ids });
  }

  assignAccessTime(
    ids: number[],
    visitorAccessTimes: VisitorAccessTime[]
  ): Observable<null> {
    return this.http.post<null>(`${this.apiUrl}/assign-access-time`, {
      visitorIds: ids,
      accessTimes: visitorAccessTimes,
    });
  }

  sendInvite(visitor: Visitor): Observable<null> {
    return this.http.post<null>(`${this.apiUrl}/send-invite`, visitor);
  }

  changePassword(changePasswordModel: ChangePassword): Observable<null> {
    return this.http.post<null>(
      `${this.apiUrl}/change-password`,
      changePasswordModel
    );
  }
}
