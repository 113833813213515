export interface CourseContent {
  id: number;
  courseId: number;
  content: string;
  publishedContent: string;
  status: CourseContentStatus;
  concurrencyToken?: number;
}

export enum CourseContentStatus {
  Published = 'Published',
  Unpublish = 'Unpublish',
  Draft = 'Draft',
}
