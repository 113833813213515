import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TabMenuComponent } from './tab-menu.component';

@NgModule({
  declarations: [TabMenuComponent],
  imports: [CommonModule],
  exports: [TabMenuComponent],
})
export class TabMenuModule {}
