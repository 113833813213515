import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiResponse, Pagination, PaginationResult } from '@models/api';
import {
  EventSchedule,
  EventScheduleList,
  GetAllEventScheduleParams,
} from '@models/schedule';
import { EnvironmentConfig, ENV_CONFIG } from '@utils/http/environment-config';
import { HttpParamsGenerator } from '@utils/http/http-params-generator';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventScheduleApiService {
  private apiUrl = `${this.envConfig.environment.baseUrl}/api/event-schedule`;

  constructor(
    @Inject(ENV_CONFIG) private envConfig: EnvironmentConfig,
    private http: HttpClient
  ) {}

  getAll(
    eventScheduleParams: GetAllEventScheduleParams,
    pagination: Pagination
  ): Observable<PaginationResult<EventScheduleList[]>> {
    const params = new HttpParamsGenerator({
      ...eventScheduleParams,
      ...pagination,
    }).generate();
    return this.http.get<PaginationResult<EventScheduleList[]>>(this.apiUrl, {
      params,
    });
  }

  getOne(id: number): Observable<ApiResponse<EventSchedule>> {
    return this.http.get<ApiResponse<EventSchedule>>(`${this.apiUrl}/${id}`);
  }

  create(eventSchedule: EventSchedule): Observable<ApiResponse<EventSchedule>> {
    return this.http.post<ApiResponse<EventSchedule>>(
      this.apiUrl,
      eventSchedule
    );
  }

  update(eventSchedule: EventSchedule): Observable<ApiResponse<EventSchedule>> {
    return this.http.put<ApiResponse<EventSchedule>>(
      `${this.apiUrl}/${eventSchedule.id}`,
      eventSchedule
    );
  }

  delete(id: number): Observable<ApiResponse<null>> {
    return this.http.delete<ApiResponse<null>>(`${this.apiUrl}?id=${id}`);
  }

  checkAvailability(
    eventSchedule: EventSchedule
  ): Observable<ApiResponse<boolean>> {
    const url = `${this.apiUrl}/check-availability`;
    return this.http.post<ApiResponse<boolean>>(url, eventSchedule);
  }
}
