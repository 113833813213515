import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiResponse, MagnoliaQueryParams } from '@models/api';
import { Asset, AssetType } from '@models/content';
import { CourseContent } from '@models/lms';
import { EnvironmentConfig, ENV_CONFIG } from '@utils/http/environment-config';
import { HttpParamsGenerator } from '@utils/http/http-params-generator';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LmsCourseContentApiService {
  private apiUrl = `${this.envConfig.environment.baseUrl}/api/lms-course-content`;

  constructor(
    @Inject(ENV_CONFIG) private envConfig: EnvironmentConfig,
    private http: HttpClient
  ) {}

  getOne(courseId: number): Observable<ApiResponse<CourseContent>> {
    return this.http.get<ApiResponse<CourseContent>>(
      `${this.apiUrl}/${courseId}`
    );
  }

  create(courseContent: CourseContent): Observable<ApiResponse<CourseContent>> {
    return this.http.post<ApiResponse<CourseContent>>(
      this.apiUrl,
      courseContent
    );
  }

  update(courseContent: CourseContent): Observable<ApiResponse<CourseContent>> {
    return this.http.put<ApiResponse<CourseContent>>(
      `${this.apiUrl}/${courseContent.id}`,
      courseContent
    );
  }

  getLmsAssets(
    assetType: AssetType,
    queryParams?: MagnoliaQueryParams
  ): Observable<ApiResponse<Asset[]>> {
    const params = new HttpParamsGenerator({
      assetType,
      ...queryParams,
    }).generate();
    const url = `${this.apiUrl}/get-lms-assets`;
    return this.http.get<ApiResponse<Asset[]>>(url, { params });
  }
}
