import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiResponse, Pagination, PaginationResult } from '@models/api';
import { Theme } from '@models/lms';
import { SortEvent } from '@models/table';
import { EnvironmentConfig, ENV_CONFIG } from '@utils/http/environment-config';
import { HttpParamsGenerator } from '@utils/http/http-params-generator';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LmsThemeApiService {
  private apiUrl = `${this.envConfig.environment.baseUrl}/api/lms-theme`;

  constructor(
    @Inject(ENV_CONFIG) private envConfig: EnvironmentConfig,
    private http: HttpClient
  ) {}

  getOne(id: number): Observable<ApiResponse<Theme>> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.get<ApiResponse<Theme>>(url);
  }

  getAll(
    pagination: Pagination,
    sort: SortEvent<Theme>,
    keyword: string
  ): Observable<PaginationResult<Theme[]>> {
    const value = {
      ...pagination,
      sorting: [sort],
      keyword,
    };
    const params = new HttpParamsGenerator(value).generate();
    return this.http.get<PaginationResult<Theme[]>>(this.apiUrl, {
      params,
    });
  }

  create(theme: Theme): Observable<ApiResponse<Theme>> {
    return this.http.post<ApiResponse<Theme>>(this.apiUrl, theme);
  }

  update(theme: Theme): Observable<ApiResponse<Theme>> {
    return this.http.put<ApiResponse<Theme>>(
      `${this.apiUrl}/${theme.id}`,
      theme
    );
  }

  delete(id: number): Observable<ApiResponse<null>> {
    const params = { id: id.toString() };
    return this.http.delete<ApiResponse<null>>(this.apiUrl, { params });
  }
}
