export type CourseContentComponent =
  | Audio
  | Document
  | EmbedUrl
  | Image
  | Poll
  | RichText
  | Video;

export enum CourseContentComponentType {
  AUDIO = 'AUDIO',
  DOCUMENT = 'DOCUMENT',
  EMBED_URL = 'EMBED_URL',
  IMAGE = 'IMAGE',
  POLL = 'POLL',
  RICH_TEXT = 'RICH_TEXT',
  VIDEO = 'VIDEO',
}

export interface Audio {
  type: CourseContentComponentType.AUDIO;
  value: string | File;
}

export interface Document {
  type: CourseContentComponentType.DOCUMENT;
  value: (string | File)[];
}

export interface EmbedUrl {
  type: CourseContentComponentType.EMBED_URL;
  value: EmbedUrlValue;
}

export interface EmbedUrlValue {
  source: string;
  url: string;
}

export interface Image {
  type: CourseContentComponentType.IMAGE;
  value: string | File;
}

export interface Poll {
  type: CourseContentComponentType.POLL;
  value: PollValue;
}

export interface PollValue {
  mode: 'single' | 'multiple';
  question: string;
  remarks: string;
  options: PollOptions[];
}

export interface PollOptions {
  description: string;
  isCorrect: boolean;
}

export interface RichText {
  type: CourseContentComponentType.RICH_TEXT;
  value: string;
}

export interface Video {
  type: CourseContentComponentType.VIDEO;
  value: string | File;
}
