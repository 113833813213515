import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiResponse, Pagination, PaginationResult } from '@models/api';
import { CourseContentSearchCriteria } from '@models/api/lms/course-content';
import { Course } from '@models/lms';
import { SortEvent } from '@models/table';
import { EnvironmentConfig, ENV_CONFIG } from '@utils/http/environment-config';
import { HttpParamsGenerator } from '@utils/http/http-params-generator';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LmsCourseApiService {
  private apiUrl = `${this.envConfig.environment.baseUrl}/api/lms-course`;

  constructor(
    @Inject(ENV_CONFIG) private envConfig: EnvironmentConfig,
    private http: HttpClient
  ) {}

  getOne(id: number): Observable<ApiResponse<Course>> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.get<ApiResponse<Course>>(url);
  }

  getAll(
    pagination: Pagination,
    sort: SortEvent<Course>,
    searchCriteria: Partial<CourseContentSearchCriteria>
  ): Observable<PaginationResult<Course[]>> {
    const value = {
      ...pagination,
      ...searchCriteria,
      sorting: [sort],
    };
    const params = new HttpParamsGenerator(value).generate();
    return this.http.get<PaginationResult<Course[]>>(this.apiUrl, {
      params,
    });
  }

  create(course: Course): Observable<ApiResponse<Course>> {
    return this.http.post<ApiResponse<Course>>(this.apiUrl, course);
  }

  update(course: Course): Observable<ApiResponse<Course>> {
    return this.http.put<ApiResponse<Course>>(
      `${this.apiUrl}/${course.id}`,
      course
    );
  }

  delete(id: number): Observable<ApiResponse<null>> {
    const params = { id: id.toString() };
    return this.http.delete<ApiResponse<null>>(this.apiUrl, { params });
  }

  visit(course: Course): Observable<null> {
    return this.http.post<null>(`${this.apiUrl}/visit`, course);
  }
}
